@import '~ag-grid-community/dist/styles/ag-grid.css';
@import '~ag-grid-community/dist/styles/ag-theme-material.css';
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:wght@500;700&display=swap');

* {
  font-family: 'Noto Sans', sans-serif;
}

.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.hoverableDiv:hover {
  transition: all 0.5s ease;
  cursor: pointer;
}

.ag-header-cell-label {
  justify-content: center;
}

.ag-header-cell-text {
  font-weight: bolder;
  color: black;
  font-size: 1.6em;
}

.ag-theme-material {
  font-size: 16px;
}
.ag-theme-material .ag-row {
  border: none;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
